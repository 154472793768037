import { $api } from "@/services/constant";
import { HOST_API_V1 } from "@/services/env-init";
import { AxiosRequestConfig, BaseResponse } from "@/services/types";
import { DetailCategory, ListCategories } from "./types";

const BASE_URL_V1_ADMIN_LEARNING_CATEGORIES =
  HOST_API_V1 + "admin/learning/categories";

export const getAdminLearningCategories = async (
  config?: AxiosRequestConfig
): Promise<BaseResponse<ListCategories[]>> => {
  const res = await $api().get(BASE_URL_V1_ADMIN_LEARNING_CATEGORIES, config);
  return res.data;
};

export const getAdminLearningCategoryById = async (
  categoryCode: string
): Promise<BaseResponse<DetailCategory>> => {
  const res = await $api().get(
    `${BASE_URL_V1_ADMIN_LEARNING_CATEGORIES}/${categoryCode}`
  );
  return res.data;
};

export const postAdminLearningCategory = async (
  payload: AxiosRequestConfig["data"]
) => {
  const res = await $api().post(
    `${BASE_URL_V1_ADMIN_LEARNING_CATEGORIES}`,
    payload
  );
  return res.data;
};

export const putAdminLearningCategory = async (
  categoryCode: string,
  payload: AxiosRequestConfig["data"]
) => {
  const res = await $api().put(
    `${BASE_URL_V1_ADMIN_LEARNING_CATEGORIES}/${categoryCode}`,
    payload
  );
  return res.data;
};

export const deleteAdminLearningCategoryById = async (categoryCode: string) => {
  const res = await $api().delete(
    `${BASE_URL_V1_ADMIN_LEARNING_CATEGORIES}/${categoryCode}`
  );
  return res.data;
};
